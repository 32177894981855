// @ts-nocheck
/* eslint @typescript-eslint/no-unused-vars: off */
/* eslint @typescript-eslint/no-explicit-any: off */

import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'
import { Box, Button, Grid } from '@mui/material'

const Bag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background: #dfeefc;
  border: 1px solid #000000;
  opacity: 0.9;
  background-color: #3b3b3b;
  padding: clamp(4px, 1vw, 6px);
  width: 100%;
  transition: all 0.3s ease;

  h2, h3 {
    font-family: 'Cooper Black', sans-serif;
    font-weight: bold;
    font-size: clamp(14px, 2.5vw, 22px);
    color: white;
    line-height: 100%;
    margin: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
`

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #3b3b3b;
  border: 1px solid #000000;
  opacity: 0.9;
  padding: clamp(1px, 0.5vw, 2px);
  width: 100%;
  aspect-ratio: 1;
  transition: all 0.2s ease;

  img {
    width: clamp(15px, 2vw, 25px);
    height: clamp(35px, 4vw, 45px);
    object-fit: contain;
    transition: transform 0.2s ease;
  }

  &:hover {
    background-color: #852d17;
    cursor: pointer;
    transform: scale(1.05);

    img {
      transform: scale(1.1);
    }
  }
`

const ButtonGroupView = styled.div`
  border: 1px solid #000000;
  background-color: rgba(59, 59, 59, 0.95);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: clamp(4px, 1vw, 8px);
  margin-top: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);

  button {
    width: 100%;
    min-width: clamp(30px, 5vw, 100px) !important;
    background-color: #9c341a;
    color: white;
    border-radius: 5px;
    font-size: clamp(8px, 1.5vw, 14px);
    padding: clamp(2px, 0.5vw, 4px) clamp(4px, 1vw, 8px);
    transition: all 0.2s ease;
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0.5px;

    &:hover {
      background-color: #852d17;
      transform: translateY(-1px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &:active {
      transform: translateY(0);
      box-shadow: none;
    }
  }
`

const ItemInfo = styled.div`
  font-family: 'Arial', sans-serif;
  font-size: clamp(8px, 1.5vw, 12px);
  color: white;
  text-align: left;
  padding: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1.2;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`

export function Inventory(data: any) {
  const brewArr = []
  const nonBrewArrMap: any[] = []
  const emptyArray = []
  
  for (let i = 0; i < data.assetsInfo?.length || 0; i++) {
    const tempAsset = data.assetsInfo[i]
    for (let j = 0; j < tempAsset.active_assets; j++) {
      brewArr.push(0)
    }
  }
  
  for (let i = 0; i < 9 - (brewArr.length + nonBrewArrMap.length); i++) {
    emptyArray.push(0)
  }

  const ShowMenuBoxRedux = data.ShowMenuBoxRedux || false

  return (
    <div
      style={{
        height: '100%',
        width: 'clamp(112px, 20vw, 200px)',
        display: 'flex',
        position: 'fixed',
        top: ShowMenuBoxRedux ? '117px' : '68px',
        left: '3px',
        zIndex: 1000,
        transition: 'all 0.3s ease',
      }}
    >
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <Bag>
              <h2>Inventory</h2>
            </Bag>
          </Grid>
          {brewArr.map((el, index) => (
            <Grid item xs={4} key={index}>
              <Item>
                <img
                  src='bitfgihter_assets/brew/BREW.png'
                  alt='Brew'
                  onMouseDown={() => {
                    data.setShowButtonGroupBool?.(!data.showButtonGroupBool)
                  }}
                />
              </Item>
            </Grid>
          ))}
          {nonBrewArrMap.map((el) => (
            <Grid item xs={4} key={uuidv4()}>
              <Item>
                <img 
                  src='bitfgihter_assets/brew/empty-brew.png' 
                  alt='Empty Brew'
                />
              </Item>
            </Grid>
          ))}
          {emptyArray.map(() => (
            <Grid item xs={4} key={uuidv4()}>
              <Item>
                <div
                  style={{
                    width: 'clamp(15px, 2vw, 25px)',
                    height: 'clamp(35px, 4vw, 45px)',
                  }}
                />
              </Item>
            </Grid>
          ))}
        </Grid>
        {data.showButtonGroupBool && (
          <ButtonGroupView>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        onClick={async (event: any) => {
                          event.preventDefault()
                          await data.useBrew?.()
                          data.setShowButtonGroupBool?.(false)
                        }}
                      >
                        Use
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        onClick={async (event: any) => {
                          event.preventDefault()
                          await data.equipBrew?.()
                          data.setShowButtonGroupBool?.(false)
                        }}
                      >
                        Equip
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <ItemInfo>Drink brew to heal your fighter</ItemInfo>
                </Grid>
              </Grid>
            </Box>
          </ButtonGroupView>
        )}
      </Box>
    </div>
  )
}