// @ts-nocheck
/* eslint @typescript-eslint/no-unused-vars: off */
/* eslint @typescript-eslint/no-explicit-any: off */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles'
import store from './stores'
import App from './App';
import reportWebVitals from './reportWebVitals';
import muiTheme from './MuiTheme'
import { Web3ReactProvider } from '@web3-react/core'
import { ExternalProvider, JsonRpcFetchFunc, Web3Provider } from "@ethersproject/providers";

import { GoogleOAuthProvider } from '@react-oauth/google';


import './index.scss';
import './PhaserGame'
import { ConectionWrapper } from './revamped/pages/landing/ConectionWrapper';


declare global {
  interface Window {
    ethereum?: any;
    Capacitor?: any;

    AndroidAppInterface?: {
      isNativeApp: () => boolean;
      performNativeGoogleLogin: () => void;
      sendGoogleSignInData: (data: string) => void;
    };
    handleGoogleSignInData?: (data: string) => void;
  }
}

const GOOGLE_CLIENT_ID = "13827582615-se2k0c0rn0989euu069njk6eefi2kts9.apps.googleusercontent.com"

// if (Capacitor.isNativePlatform() || window.AndroidAppInterface?.isNativeApp()) {
//   console.log("went here.... debug particle")
//   GOOGLE_CLIENT_ID = '13827582615-p4s1uqahoefad137rcmqqtvehdls2eqa.apps.googleusercontent.com'
// }
const container = document.getElementById('root')!;
const root = createRoot(container);

function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc) {
  return new Web3Provider(provider);
}

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ThemeProvider theme={muiTheme}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ConectionWrapper>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <App />
          </GoogleOAuthProvider>
        </ConectionWrapper>

      </Web3ReactProvider>
    </ThemeProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
