import phaserGame from "../../../PhaserGame";
import store from "../../../stores";
import { BrewMachinePunched, HitFightMachine, SelectFightInFightMachineMenu, SetMouseClickControlFightMachine } from "../../../stores/UserActions";
import { ChangeShowMenuBox, ChangeShowQueueBox } from "../../../stores/UserWebsiteStore";
import { Rect } from "../../Components/Boundary";
import Bootstrap from "../Bootstrap";
import Game from "../Game";


export class FightMachineComponent {
  FightMachineRect!: Rect[];
  bootstrap: Bootstrap;
  fightMachineExt: any[]
  game: Game

  constructor(FightMachineRect: Rect[], fightMachineExt: any[]) {
    this.game = phaserGame.scene.keys.game as Game;
    this.FightMachineRect = FightMachineRect
    this.fightMachineExt = fightMachineExt
    this.bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap;
  }




  update(tempMyPlayer: any, keysInfo: any) {

    let checkIn = false
    for (let i = 0; i < this.FightMachineRect.length; i++) {
      const rect = this.FightMachineRect[i]
      const ext = this.fightMachineExt[i]
      if ((tempMyPlayer.gameObject.playerContainer.x > rect.leftX && tempMyPlayer.gameObject.playerContainer.x < rect.leftX + rect.width)
        && (tempMyPlayer.gameObject.playerContainer.y > rect.leftY && tempMyPlayer.gameObject.playerContainer.y < rect.leftY + rect.height)
      ) {
        checkIn = true
        if ((keysInfo.keyK.pressed || keysInfo.keyP.pressed)) {
          let check = false;
          store.getState().userPathStore.CombinedQueueData.map(_data => {
            if (_data.p1_wallet === store.getState().web3store.userAddress || _data.p2_wallet === store.getState().web3store.userAddress) {
              check = true
            }
          })
          if (store.getState().queueDetailedInfo.added_to_queue_pool) {
            check = true
          }

          if (!check) {

            if (!store.getState().userActionsDataStore.hitFightMachine) {
              store.dispatch(HitFightMachine(true))
              ext.setDepth(-1)
              setTimeout(() => {
                ext.setDepth(1)
              }, 500)
            } else {
              store.dispatch(SelectFightInFightMachineMenu(false))
            }
          } else {
            store.dispatch(ChangeShowQueueBox(!store.getState().userPathStore.ShowQueueBox))
            store.dispatch(ChangeShowMenuBox(!store.getState().userPathStore.ShowMenuBox))

          }
        }
      }
    }


    if (!checkIn) {
      store.dispatch(HitFightMachine(false));
      store.dispatch(SelectFightInFightMachineMenu(false))
      store.dispatch(SetMouseClickControlFightMachine(false))
    }

  }
}