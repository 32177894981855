import { useState, useEffect } from 'react';
import { isMobile as rddIsMobile, isTablet as rddIsTablet, isBrowser as rddIsBrowser } from 'react-device-detect';

export function useDeviceDetector() {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    // console.log('----$$$---', rddIsMobile, isTablet, browserName);
    setIsMobile(rddIsMobile);
    setIsTablet(rddIsTablet);
    setIsBrowser(rddIsBrowser)
  }, [rddIsMobile, rddIsTablet, rddIsBrowser]);

  return { isMobile, isTablet, isBrowser };
}
