// @ts-nocheck
/* eslint @typescript-eslint/no-unused-vars: off */
/* eslint @typescript-eslint/no-explicit-any: off */

import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks"
import styled from 'styled-components'
import { Box, Button, TextField } from "@mui/material"
import { TurnMouseClickOff } from "../../stores/UserActions";
import { ChangeShowControls } from "../../stores/UserWebsiteStore";
import { getSystemInfo } from '../../utils/systemInfo';
import { useDetectClickOutside } from "react-detect-click-outside";
import { SetWeb2PlayerNameApiCall } from "../../hooks/ApiCaller";

const Backdrop = styled.div`
    position: fixed;
    top: calc(30% + 60px);
    left:30%;
    height: 100%;
    width: 30%;
    @media only screen and (max-height: 640px){
        width: 100%;
        left:0%;
    }   
`

const Backdrop2 = styled.div`
    position:fixed;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    // z-index: 1;
`

const Wrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    
`

const FightConfirmationBoxDiv = styled(Box)`
    width: 100%;
    overflow: auto;
    opacity: 1;
    background: #000000a7;
    border: 5px solid #000000;
    border-radius: 10px;

    h2 {
        font-family: Monospace;
        font-style: bold;
        color: white;
    }

    span {
        font-family: Monospace;
        font-style: bold;
    }

    .controlsText {
        font-family: Monospace;
        font-style: bold;
        color: white;
        background: rgb(84, 86, 86);
    }
`

export function NameFormWeb2Player() {
    const showNmaeFormForWeb2Player = useAppSelector((state) => state.userPathStore.showNmaeFormForWeb2Player)
    const dispatch = useAppDispatch();
    const ismobile = getSystemInfo();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isHardware, setIsHardware] = useState(window.innerWidth <= 640);

    const [loading, setLoading] = useState(false)
    const [bbdName, setBBDName] = useState('')

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            setIsHardware(window.innerWidth <= 640);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    function closeView() {
        // localStorage.setItem("saw_controls", "YES")
        // if (localStorage.getItem("saw_controls"))
        //     dispatch(ChangeShowControls(false))
    }
    const ref = useDetectClickOutside({ onTriggered: closeView });

    async function submitAndSave() {
        setLoading(true)
        try {
            //
            const response = await SetWeb2PlayerNameApiCall(bbdName);
            if (response.success) {
                console.log("name saved .. created")
                window.location.reload();
            }
        } catch (err) {
            //
        }
        setLoading(false)
    }

    const View = <Wrapper ref={ref}>
        <FightConfirmationBoxDiv className="text-center">
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around'
            }}>
                <div style={{
                    justifyContent: 'space-around',
                    paddingTop: '2vh',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    padding: '20px'
                }}>
                    <h2 className="fs-3">
                        Name your Bit Buddy!
                    </h2>

                    <TextField
                        id="outlined-basic"
                        label="Your Name"
                        variant="outlined"
                        value={bbdName}
                        onChange={(e) => {
                            setBBDName(e.target.value)
                        }}
                    />
                    <Button variant="contained" onClick={(e) => {
                        e.preventDefault()
                        submitAndSave()
                    }}>
                        Go!
                    </Button>




                </div>


                <div style={{
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    paddingTop: '4vh',
                    paddingBottom: "2vh"
                }}>
                </div>
            </div>
        </FightConfirmationBoxDiv>
    </Wrapper>
    return (
        <div
            onMouseOver={() => {
                dispatch(TurnMouseClickOff(true))
            }}
            onMouseOut={() => {
                dispatch(TurnMouseClickOff(false))
            }}
            style={{
                zIndex: 5
            }}
        >
            {
                showNmaeFormForWeb2Player &&
                <div>
                    {
                        !getSystemInfo() ?
                            <Backdrop>
                                {View}
                            </Backdrop> :
                            <Backdrop2>
                                {View}
                            </Backdrop2>
                    }
                </div>
            }
        </div>
    )
}