import Divider from "../../components/Divider";
import landingPageAboutSectionBoxingRingImg from "../../assets/images/landing-page-about-section-boxing-ring.webp";
import landingPageAboutSectionLeftCoinsImg from "../../assets/images/landing-page-about-section-left-coins.webp";
import landingPageAboutSectionRightCoinsImg from "../../assets/images/landing-page-about-section-right-coins.webp";
import landingPageAboutSectionCharacterImg1 from "../../assets/images/landing-page-about-section-character-1.gif";
import landingPageAboutSectionCharacterImg2 from "../../assets/images/landing-page-about-section-character-2.gif";
import fight from "../../assets/images/fight.gif";
import landingPageAboutSectionImg1 from "../../assets/images/landing-page-about-section-1.webp";
import landingPageAboutSectionImg2 from "../../assets/images/landing-page-about-section-2.webp";

export default function AboutSection() {
  return (
    <section className="about-section">
      <Divider hasHangingLight />

      <div className="container">
        <div className="h1-wrapper">
          <h1 className="text">
            The  <br />Bitcoin  <br />Game!
          </h1>
          <h1 className="text-stroke">
            The  <br />Bitcoin  <br />Game!</h1>
          <h1 className="text-shadow">
            The  <br />Bitcoin  <br />Game!</h1>
        </div>

        <p>
          Bit Fighters is a zany universe where you collaborate, compete, & immerse yourself in a player
          built economy.
          <br />
          <br />
          Players own the in-game businesses and prove their work in cities ruled by players.
          <br />
          The economy system provides
          players many different opportunities to create value, offering a range of gameplay.<br />
          <br />
          You never have to fight. But you can if you want to.
        </p>

        <div className="boxing-ring-wrapper">
          <img
            src={landingPageAboutSectionLeftCoinsImg}
            alt=""
            className="left-coins-img"
          />
          <img
            src={fight}
            alt=""
            className="fight"
          />
          <img
            src={landingPageAboutSectionRightCoinsImg}
            alt=""
            className="right-coins-img"
          />
        </div>
        <p>
          You can socialize while playing rewarding mini-games or work with Dr. Bitz and his team of Bit Fighters to save their
          world from total annihilation!
          <br />
          <br />
          Run a business, lead a gang, create economy routes with other players and build entire empiries by owning cities.
          <br />
          <br />
          It's your turn to win!
        </p>
      </div>

      <img src={landingPageAboutSectionImg1} alt="" className="img-1" />
      <img src={landingPageAboutSectionImg2} alt="" className="img-2" />
    </section>
  );
}
