import { useEffect, useState } from "react";
import styled from "styled-components"
import { isNullOrUndefined } from "util";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ChangeRegisterShowInGame } from "../../stores/UserWebsiteStore";
import { Modal, Box, Button, Typography } from '@mui/material'
import { Google as GoogleIcon } from '@mui/icons-material' // If you have Google icon or use an image
import metamaskLogo from '../../revamped/assets/images/metamask.svg'
// import googleLogo from '../../../revamped/assets/images/google.svg'
import googleLogo from '../../revamped/assets/images/google.svg'

import { GoogleLogin } from '@react-oauth/google'
import { useGoogleLogin } from '@react-oauth/google'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import AnimatedLoader from "../../revamped/FullScreenLoader/AnimatedLoader";
import { Capacitor } from "@capacitor/core";

import MetaMaskOnboarding from '@metamask/onboarding'
import { SetFailureNotificationBool, SetFailureNotificationMessage } from "../../stores/NotificationStore";
import store from "../../stores";
import { Web3Login } from "../../landing-page/Web3Login";
import { assignBitfighterToEmail } from "../../hooks/ApiCaller";


const Wrapper = styled.div`
  position:fixed;
  right:0;
  bottom:0;
  z-index:1;
`

const TextBox = styled.div`
  overflow: auto;
  opacity: 0.9;
  background: #2c2c2c;
  border: 5px solid #000000;
  border-radius: 10px;
  padding: 20px;

  span {
    font-family: Monospace;
    font-style: bold;
    font-size: 20px;
    color: aliceblue;
  }

  h2 {
    font-family: Monospace;
    font-style: bold;
    font-size: 30px;
    color: white;
  }

  button {
    background-color: #9c341a;
    font-family: Monospace;
    font-style: bold;
    font-size: 20px;
    color: aliceblue;
    padding: 10px;
    border-radius: 16px
  }
`

export function RegisterNow() {
  // const web3ConnectedUser = useAppSelector((state) => state.web3store.web3Connected);
  // const dispatch = useAppDispatch();
  const [showRegister, setShowRegister] = useState(false)

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const metaMaskInstalledBool = useAppSelector((state) => state.userPathStore.metaMaskInstalled)
  const onboarding = new MetaMaskOnboarding()

  const selectedPlayer = useAppSelector((state) => state.playerDataStore.current_game_player_info)

  const handleMetaMaskLogin = () => {
    console.log('MetaMask login flow triggered')
    connectButtonHandle()
  }

  const connectButtonHandle = async () => {
    setIsLoading(true)
    const selectedNetwork = localStorage.getItem("network_connected") || "AVALANCHE"
    console.log('click connectButtonHandle -- ', selectedNetwork)
    handleClose()

    if (selectedNetwork == '') {
      store.dispatch(SetFailureNotificationBool(true))
      store.dispatch(SetFailureNotificationMessage('Connect Wallet'))
      setIsLoading(false)
      return
    }

    // const provider = await detectEthereumProvider();
    handleClose()

    if (!metaMaskInstalledBool) {
      onboarding.startOnboarding()
    } else if (metaMaskInstalledBool) {
      await Web3Login()
    }

    setIsLoading(false)
    handleClose()
  }

  const handleGoogleLoginClick = async () => {
    console.log("inside login flow --- ", Capacitor.isNativePlatform())
    const isNativeApp = typeof window.AndroidAppInterface !== "undefined" && window.AndroidAppInterface.isNativeApp();
    console.log("Is Native App: ", isNativeApp);
    if (Capacitor.isNativePlatform() || isNativeApp) {
      try {
        try {
          console.log("Is inside App: ");
          initiateGoogleLogin()
        } catch (err) {
          console.log("error in initiate google login")
        }
      } catch (error: any) {
        // console.error("Google Login Failed:", error);
        console.error("Google Login Failed:", error);
        // Log the error details to see what went wrong
        if (error && error?.message) {
          console.error("Error Message: ", error.message);
        }
        if (error && error?.stack) {
          console.error("Stack Trace: ", error.stack);
        }
        // console.error("Stack Trace:", error || "No stack trace available");
      }
      // }
    } else {
      console.log("Is browser App: ");
      login();
    }
    // login();
  };

  const fetchUserInfo = async (accessToken: string) => {
    try {
      const response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      const data = await response.json()
      return data
    } catch (error) {
      console.error('Error fetching user info:', error)
    }
  }

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      console.log('Login successful:', response);
      setIsLoading(true)

      const userInfo = await fetchUserInfo(response.access_token)
      await handleGoogleLoginV2(userInfo)

      setIsLoading(false)
    },
    onError: () => {
      console.error('Login Failed')
    },
  })

  const handleGoogleLoginV2 = async (decoded: any) => {
    if (decoded?.email) {
      // call the api to save.. 
      const res = await assignBitfighterToEmail(selectedPlayer.user_wallet_address, decoded.email, selectedPlayer.minted_id)
      if (res) {
        localStorage.setItem('web2_wallet_address', decoded.email)
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    }
  }

  const handleClose = () => setOpen(false)


  function initiateGoogleLogin() {
    return new Promise((resolve, reject) => {
      if (window.AndroidAppInterface && window.AndroidAppInterface.performNativeGoogleLogin) {
        // Define a temporary function to resolve the promise
        window.handleGoogleSignInData = (email) => {
          console.log("Received email from native: ", email);
          handleGoogleLoginV2({ "email": email })
          resolve(email); // Resolve the promise with the email
        };

        // Initiate the native login
        console.log("Initiating native Google login...");
        window.AndroidAppInterface.performNativeGoogleLogin();
      } else {
        console.error("Native interface is not available.");
        reject("Native interface not available"); // Reject the promise on error
      }
    });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const web2_free_play = localStorage.getItem("web2_free_play");
      const web2_wallet_address = localStorage.getItem("web2_wallet_address");
      if (web2_free_play == web2_wallet_address) {
        setShowRegister(true);
      } else {
        setShowRegister(false);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [])


  const registerButtonClicked = () => {
    console.log("register button clicked..", selectedPlayer)
    setOpen(true)
  }

  return (
    <div>
      {isLoading && <AnimatedLoader />}
      {showRegister &&
        <Wrapper>
          <TextBox onClick={() => registerButtonClicked()}>
            <span>Save your Bit Buddy.</span>
            <br />
            <button> Register Now </button>
          </TextBox>
        </Wrapper>
      }

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='login-modal-title'
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)', // Darker backdrop
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            borderRadius: '16px',
            border: '4px solid black',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            gap: '40px',
          }}
          className='cursor-pointer'
        >
          {/* <img src={metamaskLogo} alt='MetaMask Logo' style={{ width: 50, height: 50 }} onClick={handleMetaMaskLogin} /> */}
          <img
            src={googleLogo}
            alt='MetaMask Logo'
            style={{ width: 50, height: 50 }}
            onClick={() => handleGoogleLoginClick()}
          />
        </Box>
      </Modal>
    </div>

  )
}