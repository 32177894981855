// @ts-nocheck
/* eslint @typescript-eslint/no-unused-vars: off */
/* eslint @typescript-eslint/no-explicit-any: off */

import { fetchAllNFTsFromDbEntries } from "../hooks/FetchNFT";
import {
  setNFTDetails,
  setNFTLoadedBool,
  setTotalNFTData,
} from "../stores/BitFighters";
import { validation } from "../utils/Validation";
import { ethers } from "ethers";
import {
  ChangeAuthTOken,
  ChangeLoggerMessage,
  ChangeUserData,
  ChangeValidUserState,
  USER_DETAILS,
} from "../stores/UserWebsiteStore";
import {
  checkIfUserSignedMetamask,
  fetchNFTsFromDB,
  loginAndAuthenticateUser,
  postUserSignedMessage,
  updateNFTsInDB,
  UpdateUserNetwork,
} from "../hooks/ApiCaller";
import store from "../stores";
import { Login, SetConnectedNetwork, SetConnectedWeb3, SetWeb3Free } from "../stores/Web3Store";
import MetaMaskOnboarding from "@metamask/onboarding";
import detectEthereumProvider from "@metamask/detect-provider";
import {
  FetchDripPresaleInfoMintedByUser,
  FetchPresaleInfoMintedByUser,
  getBalances,
  updateBitfightersMintedCountAndTotal,
  updateDripPresaleMintedCount,
  updateOneKClubMintedCount,
  updatePresaleMintedCount,
} from "../utils/web3_utils";
import { setCardState } from "../stores/MintCardStateStore";
import { PageStates } from "./components/SidePanel/SidePanel";
import { Buffer } from "buffer";
import { ReaderFunctions } from "../contract/ReaderFunctions";
import { SetFailureNotificationBool, SetFailureNotificationMessage } from "../stores/NotificationStore";
import Mixpanel from "../mixpanel";
// import { useDeviceDetector } from "../utils/device_detect";



// @ts-ignore: Ignore the TypeScript error for the next lines
declare global {
  interface Window {
    // @ts-ignore: Ignore the TypeScript error for the next lines
    ethereum?: any;
    // @ts-ignore: Ignore the TypeScript error for the next lines
    Capacitor?: any;
  }
}

const siweSign = async (accounts: Array<string>, siweMessage: string) => {
  console.log("in siweSign");
  try {
    const from = accounts[0];
    // const hashedMessage = Web3.utils.sha3(message);
    const msg = `0x${Buffer.from(siweMessage, "utf8").toString("hex")}`;
    // @ts-ignore: Ignore the TypeScript error for the next line
    const sign = await window.ethereum.request({
      method: "personal_sign",
      params: [msg, from],
    });
    console.log("in siweSign ", sign);
    return sign;
  } catch (err) {
    console.error("error in siweSign", err);
    return "Error";
  }
};

const SignatureMessage =
  "By interacting with this game you are acknowledging that you have read, understood, and agree to be bound by the terms and conditions found here: https://docs.bitfighters.club/terms-of-service Failure to comply with these terms and conditions may result in, but will not be limited to, disqualification from participation in the game and the forfeiture of your account and all associated game assets. Sign to confirm and continue.";

export async function Web3Login() {
  console.log("in web3login ", window.ethereum);
  const onboarding = new MetaMaskOnboarding();

  // const { isMobile, isBrowser, isTablet } = useDeviceDetector()




  if (!store.getState().userPathStore.metaMaskInstalled) {
    // if (isMobile) {
    //   window.location.href = "metamask://dapp/https://bitfighters.club";
    //   return
    // }
    onboarding.startOnboarding();
    return;
  }

  const connectedNetwork = store.getState().web3store.web3Network;

  if (connectedNetwork == '') {
    store.dispatch(SetFailureNotificationBool(true))
    store.dispatch(SetFailureNotificationMessage("Please Select a Network"))
    return
  }
  console.log("in web3login ....(*******", process.env.REACT_APP_DEV_ENV);
  // @ts-ignore: Ignore the TypeScript error for the next lines
  await window.ethereum.enable();
  // @ts-ignore: Ignore TypeScript errors for the entire file
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const network = await provider.getNetwork();
  if (process.env.REACT_APP_DEV_ENV === "production") {

    let SUPPORTED_CHAINIDS = [];
    let SUPPORTED_NETWORK_LONG = "";
    if (connectedNetwork == 'AVALANCHE') {
      SUPPORTED_CHAINIDS = [43114];
      SUPPORTED_NETWORK_LONG = "Avalanche Network";
    } else if (connectedNetwork == 'BOB') {
      SUPPORTED_CHAINIDS = [60808];
      SUPPORTED_NETWORK_LONG = "BOB";
    } else if (connectedNetwork == 'BASE') {
      SUPPORTED_CHAINIDS = [8453];
      SUPPORTED_NETWORK_LONG = "Base Mainnet";
    }
    // store.dispatch(SetConnectedNetwork("bob"));
    console.log("in web3login ....*******", network.name, process.env.NODE_ENV);
    if (SUPPORTED_CHAINIDS.indexOf(network.chainId) === -1) {
      if (
        window.confirm(
          `Only ${SUPPORTED_NETWORK_LONG} is currently supported. Would you like to switch to ${SUPPORTED_NETWORK_LONG} now?`
        ) == true
      ) {
        let check;
        if (connectedNetwork == 'AVALANCHE') {
          // @ts-ignore: Ignore TypeScript errors for the entire file
          check = await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0xA86A",
                rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
                chainName: "Avalanche Network",
                nativeCurrency: {
                  name: "AVAX",
                  symbol: "AVAX",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://snowtrace.io/"],
              },
            ],
          });
        } else if (connectedNetwork == 'BOB') {
          // @ts-ignore: Ignore TypeScript errors for the entire file
          check = await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0xed88",
                rpcUrls: ["https://rpc.gobob.xyz/"],
                chainName: "BOB Mainnet",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                // blockExplorerUrls: ["https://snowtrace.io/"],
              },
            ],
          });
        } else if (connectedNetwork == 'BASE') {
          check = await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x2105",
                rpcUrls: ["https://mainnet.base.org"],
                chainName: "Base Mainnet",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://base.blockscout.com/"],
              },
            ],
          });
        }
        console.log("check..", check);
      } else {
        return;
      }
    }
  } else {
    let SUPPORTED_CHAINIDS = [];
    let SUPPORTED_NETWORK_LONG = "";
    if (connectedNetwork == 'AVALANCHE') {
      SUPPORTED_CHAINIDS = [43113];
      SUPPORTED_NETWORK_LONG = "AVAX Testnet";
    } else if (connectedNetwork == 'BOB') {
      SUPPORTED_CHAINIDS = [808813];
      SUPPORTED_NETWORK_LONG = "BOB Testnet";
    } else if (connectedNetwork == 'BASE') {
      SUPPORTED_CHAINIDS = [84532];
      SUPPORTED_NETWORK_LONG = "Base Sepolia";
    }
    // store.dispatch(SetConnectedNetwork("bob"));
    if (SUPPORTED_CHAINIDS.indexOf(network.chainId) === -1) {
      if (
        window.confirm(
          `Only ${SUPPORTED_NETWORK_LONG} is currently supported. Would you like to switch to ${SUPPORTED_NETWORK_LONG} now?`
        ) == true
      ) {
        // @ts-ignore: Ignore more errors as needed
        let check;
        if (connectedNetwork == 'AVALANCHE') {
          check = await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0xA869",
                rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
                chainName: "Avalanche Testnet C-Chain",
                nativeCurrency: {
                  name: "AVAX",
                  symbol: "AVAX",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://testnet.snowtrace.io/"],
              },
            ],
          });
        } else if (connectedNetwork == 'BOB') {
          check = await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0xc576d",
                rpcUrls: ["https://bob-sepolia.rpc.gobob.xyz"],
                chainName: "BOB Testnet",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
                // blockExplorerUrls: ["https://testnet.snowtrace.io/"],
              },
            ],
          });
        } else if (connectedNetwork == 'BASE') {
          check = await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x14A34",
                rpcUrls: ["https://sepolia.base.org"],
                chainName: "Base Sepolia",
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
              },
            ],
          });
        }






        console.log("check..", check);
      } else {
        return;
      }
    }
  }
  console.log("here debug ")
  const accounts = await provider.send("eth_requestAccounts", []);

  // check if signature exist in DB
  const userMetamaskSigned = await checkIfUserSignedMetamask(accounts[0]);
  console.log("Validation before siweSign -- ", userMetamaskSigned);
  if (!userMetamaskSigned) {
    const signedRes = await siweSign(accounts, SignatureMessage);
    if (signedRes === "Error") {
      window.alert("Failed Metamask signature. Without that you cannot play.");
      return;
    }
    // post data to db
    postUserSignedMessage(accounts[0], signedRes);
    // verifyMetamaskSignature(accounts[0], SignatureMessage, signedRes)
  }

  localStorage.setItem("connected_matic_network", "10");
  localStorage.setItem("last_web3_login_time", (new Date()).toISOString())
  // const timestamp = new Date().toISOString();
  console.log("-------accounts---- .");
  console.log(accounts[0]);
  console.log("----------- .");
  if (!validation(accounts[0])) {
    store.dispatch(ChangeValidUserState(false));
    return;
  }
  store.dispatch(Login(accounts[0]));
  // store.dispatch(Login(accounts[0]));
  // store.dispatch(Login(accounts[0]));

  localStorage.setItem("web3_wallet_address", accounts[0])


  store.dispatch(SetConnectedWeb3(true));
  store.dispatch(setCardState(PageStates.ProgressState));
  store.dispatch(ChangeValidUserState(true));

  // check if user owns 1k club card - prod

  const auth_token: string = await loginAndAuthenticateUser(accounts[0]);
  store.dispatch(ChangeAuthTOken(auth_token));

  await UpdateUserNetwork()

  const rc = new ReaderFunctions();
  const fetchTokensOfUserFromSC = await rc.fetchTokenOfUserFromSC();



  // const fetchTokensOfUserFromSC =
  //   await ReaderFunctions.fetchTokenOfUserFromSC();
  let result = await fetchNFTsFromDB(accounts[0]);
  console.log("______debug_tokenIds____web2__ ", result.message.length);
  // if mismatch. then call. the update function.
  if (result.message.length !== fetchTokensOfUserFromSC.length) {
    if (
      !(
        store.getState().web3store.userAddress === "0x49d318a4f85936fe49d86c0c5a0633bc27ec480c"
        || store.getState().web3store.userAddress === "0x854b7f5dc5e6a96c076a0fae6d5c8dc334a2dd77"
        // || store.getState().web3store.userAddress === "0xb4c2d38ca5382b565cb9e8f849da42d8e441b59e"
      )
    ) {
      await updateNFTsInDB(accounts[0]);
    }
  }
  result = await fetchNFTsFromDB(accounts[0]);

  // try {
  const count_of_nft = result?.message.length
  if (count_of_nft > 0) {
    console.log("proper web3 wallet coz minted more than 1-> ", count_of_nft)
    store.dispatch(SetWeb3Free(false))
  } else {
    console.log("not proper web3 wallet coz minted 0 -> ", count_of_nft)
    store.dispatch(SetWeb3Free(true))
  }
  // } catch (err) {
  //   //
  // }


  const dataOfNFTS = await fetchAllNFTsFromDbEntries(result.message);
  store.dispatch(setTotalNFTData(result.message));
  store.dispatch(setNFTDetails(dataOfNFTS));
  store.dispatch(setNFTLoadedBool(true));


  await getBalances(store.getState().web3store.userAddress);

  Mixpanel.identify(accounts[0]);
  Mixpanel.track('Web3Login');
  Mixpanel.people.set({
    network: store.getState().web3store.web3Connected ? store.getState().web3store.web3Network.toLowerCase() : 'web2'
  });

  // update nfts infos
  await updateBitfightersMintedCountAndTotal();
  await updatePresaleMintedCount();
  await updateOneKClubMintedCount();
  await FetchPresaleInfoMintedByUser();
}


export async function PhoneWeb3Login() {

  console.log("Detecting Ethereum provider...");
  const provider = await detectEthereumProvider();

  if (provider) {
    console.log("Ethereum successfully detected!");
    store.dispatch(ChangeLoggerMessage("Ethereum successfully detected!"));
    // Further actions, like enabling accounts
    try {
      await provider.request({ method: 'eth_requestAccounts' });
    } catch (err) {
      console.error("User rejected account access", err);
    }
  } else if (window.ethereum) {
    console.log("Ethereum provider detected via window.ethereum");
    store.dispatch(ChangeLoggerMessage("Ethereum provider detected via window.ethereum"));
  } else {
    console.error("Please install MetaMask!");
    store.dispatch(ChangeLoggerMessage("Please install MetaMask!"));
    // window.location.href = "metamask://"
    window.location.href = "metamask://dapp/https://bitfighters.club";
  }

}




export async function Web3LoginV2(userAddress) {
  console.log("running Web3LoginV2 ....(*******", userAddress, window.ethereum, process.env.REACT_APP_DEV_ENV);


  const accounts = [userAddress]
  const userMetamaskSigned = await checkIfUserSignedMetamask(accounts[0]);

  console.log("running Web3LoginV2 ....(******* userMetamaskSigned", userMetamaskSigned);


  if (!userMetamaskSigned) {
    const signedRes = await siweSign(accounts, SignatureMessage);
    if (signedRes === "Error") {
      window.alert("Failed Metamask signature. Without that you cannot play.");
      return;
    }
    postUserSignedMessage(accounts[0], signedRes);
  }

  localStorage.setItem("connected_matic_network", "10");
  localStorage.setItem("last_web3_login_time", (new Date()).toISOString())
  if (!validation(accounts[0])) {
    store.dispatch(ChangeValidUserState(false));
    return;
  }
  store.dispatch(Login(accounts[0]));
  store.dispatch(Login(accounts[0]));
  store.dispatch(SetConnectedWeb3(true));
  store.dispatch(setCardState(PageStates.ProgressState));
  store.dispatch(ChangeValidUserState(true));

  // check if user owns 1k club card - prod

  const auth_token: string = await loginAndAuthenticateUser(accounts[0]);
  store.dispatch(ChangeAuthTOken(auth_token));

  await UpdateUserNetwork()

  const rc = new ReaderFunctions();
  const fetchTokensOfUserFromSC = await rc.fetchTokenOfUserFromSC();

  let result = await fetchNFTsFromDB(accounts[0]);
  if (result.message.length !== fetchTokensOfUserFromSC.length) {
    if (
      !(
        store.getState().web3store.userAddress === "0x49d318a4f85936fe49d86c0c5a0633bc27ec480c"
        || store.getState().web3store.userAddress === "0x854b7f5dc5e6a96c076a0fae6d5c8dc334a2dd77"
        // || store.getState().web3store.userAddress === "0xb4c2d38ca5382b565cb9e8f849da42d8e441b59e"
      )
    ) {
      await updateNFTsInDB(accounts[0]);
    }
  }
  result = await fetchNFTsFromDB(accounts[0]);
  console.log("debug particle ", result)

  // try {
  const count_of_nft = result?.message.length
  console.log("debug particle ", count_of_nft)

  if (count_of_nft > 0) {
    console.log("particle proper web3 wallet coz minted more than 1-> ", count_of_nft)
    store.dispatch(SetWeb3Free(false))
  } else {
    console.log("particle not proper web3 wallet coz minted 0 -> ", count_of_nft)
    store.dispatch(SetWeb3Free(true))
  }
  // } catch (err) {
  //   //
  // }


  Mixpanel.identify(accounts[0]);
  Mixpanel.track('Web3Login');
  Mixpanel.people.set({
    network: store.getState().web3store.web3Connected ? store.getState().web3store.web3Network.toLowerCase() : 'web2'
  });


  const dataOfNFTS = await fetchAllNFTsFromDbEntries(result.message);
  store.dispatch(setTotalNFTData(result.message));
  store.dispatch(setNFTDetails(dataOfNFTS));
  store.dispatch(setNFTLoadedBool(true));


  await getBalances(store.getState().web3store.userAddress);

  // update nfts infos
  await updateBitfightersMintedCountAndTotal();
  await updatePresaleMintedCount();
  await updateOneKClubMintedCount();
  await FetchPresaleInfoMintedByUser();
}
