import phaserGame from "../../../PhaserGame";
import store from "../../../stores";
import { BrewMachinePunched } from "../../../stores/UserActions";
import { Rect } from "../../Components/Boundary";
import Bootstrap from "../Bootstrap";
import Game from "../Game";


export class BrewComponent {
  brewRect!: Rect;
  bootstrap: Bootstrap;
  brewExt: any

  constructor(brewRect: Rect, brewExt: any) {
    this.brewRect = brewRect
    this.brewExt = brewExt
    this.bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap;
  }




  update(tempMyPlayer: any, keysInfo: any) {
    //Brew Machine
    // console.log("updating ... brew componetn")
    if (
      (tempMyPlayer.gameObject.playerContainer.x >= this.brewRect.leftX
        && tempMyPlayer.gameObject.playerContainer.x < this.brewRect.leftX + this.brewRect.width)
      && ((tempMyPlayer.gameObject.playerContainer.y > this.brewRect.leftY
        && tempMyPlayer.gameObject.playerContainer.y < this.brewRect.leftY + this.brewRect.height))
    ) {
      if ((keysInfo.keyK.pressed || keysInfo.keyP.pressed)) {
        console.log("overlap brew and kick", store.getState().userActionsDataStore.brewMachinePunched);
        this.brewExt.setDepth(-1)
        setTimeout(() => {
          store.dispatch(BrewMachinePunched(!store.getState().userActionsDataStore.brewMachinePunched))
          this.brewExt.setDepth(1)
          this.bootstrap.play_button_press_sound()
        }, 500)
      }
    } else {
      store.dispatch(BrewMachinePunched(false))
    }
  }
}