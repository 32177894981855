// @ts-nocheck
/* eslint @typescript-eslint/no-unused-vars: off */
/* eslint @typescript-eslint/no-explicit-any: off */
// import { useAppDispatch, useAppSelector } from "../../hooks"
import * as React from 'react'
import styled from 'styled-components'
import { Box, Button } from '@mui/material'
import Switch from '@mui/material/Switch'
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded'
import WavesRoundedIcon from '@mui/icons-material/WavesRounded'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { SetMouseClickControlFightMachine, TurnMouseClickOff } from '../../../stores/UserActions'
import store from '../../../stores'
import { Col, Row } from 'react-bootstrap'
import { ChangeShowMenuBox, ChangeShowQueueBox } from '../../../stores/UserWebsiteStore'

const GameInfoBox = styled(Box)`
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  width: 60%;
  height: 36%;
  background: #2c2c2c;
  border: 4px solid #000000;
  border-radius: 10px;
  opacity: 0.9;
  position: fixed;
  //max-height: calc(100vh - 150px); /* Adjust based on the viewport */
  //overflow: visible; /* Allow the content to be visible without scrolling */
  input {
    color: black;
    width: 30%; /* Set desired width of the input */
    border-radius: 5px; /* Rounded corners */
  }
  @media only screen and (orientation: landscape) {
    width: 40%;
    height: 25%;
    left: 30%;
    top: 10%;
  }
  @media only screen and (max-height: 900px) and (orientation: portrait) {
    width: 80%;
    height: 25%;
    top: 13%;
    left: 10%;
  }
`


const ModalContent = styled.div`
  color: white;
  font-family: 'Cooper Black', sans-serif;
  font-size: 18px;
  text-align: center; /* Center text horizontally */
  @media only screen and (orientation: landscape) {
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
  @media only screen and (max-height: 900px) and (orientation: portrait) {
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
`

export default function AddToQueueBox(data: IQueueOptions) {
  const ref = useDetectClickOutside({ onTriggered: data.closeFunction })
  const dispatch = useAppDispatch()
  // const [dark, setDark] = React.useState<boolean>(false)

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        alignItems: 'center' /* Center vertically */,
        height: '100vh' /* Take full height of the viewport */,
        zIndex: 30,
      }}
      onMouseOver={() => {
        dispatch(SetMouseClickControlFightMachine(true))
      }}
      onMouseOut={() => {
        dispatch(SetMouseClickControlFightMachine(false))
      }}
    >
      <GameInfoBox sx={{ display: 'grid', gap: 0, alignItems: 'center', flexWrap: 'wrap' }}>
        <ModalContent>Rules: 1v1</ModalContent>
        <ModalContent>Time: 1 min</ModalContent>
        <ModalContent>Bet: 20 Bits</ModalContent>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Button
            style={{ width: '80%', marginRight: '10px' }}
            sx={{ padding: '4px 16px' }}
            variant='contained'
            color='primary'
            onClick={() => {
              // data.setAmount(20)
              data.enterQueue()
              dispatch(TurnMouseClickOff(false))
            }}
          >
            <ModalContent>Fight!</ModalContent>
          </Button>
          <Button
            style={{ width: '80%' }}
            variant='contained'
            color='primary'
            sx={{ padding: '4px 16px' }}
            onClick={() => {
              dispatch(TurnMouseClickOff(false))
              store.dispatch(ChangeShowQueueBox(true))
              store.dispatch(ChangeShowMenuBox(true))
            }}
          >
            <ModalContent>Bet!</ModalContent>
          </Button>
        </Box>
      </GameInfoBox>
    </div>
  )
}
