import '../../css/index.css'
import '../../css/common.css'
import ScrollToTop from '../../components/ScrollToTop'
import NavigationBar from '../../components/NavigationBar'
import Footer from '../../components/Footer'
import HeroSection from './HeroSection'
import AboutSection from './AboutSection'
// import MineSection from './MineSection'
import VideoSection from './VideoSection'
import InfoSection from './InfoSection'
import FeaturesSection from './FeaturesSection'
import FAQsSection from './FAQsSection'
import BottomTextView from '../../../landing-page/BottomTextView'
import { useAppSelector } from '../../../hooks'
import { useLocation } from 'react-router-dom'
import phaserGame from '../../../PhaserGame'
import Bootstrap from '../../../game/scenes/Bootstrap'
import Game from '../../../game/scenes/Game'
import store from '../../../stores'
import { SetGameStarted } from '../../../stores/PlayerData'
import { ChangePath } from '../../../stores/UserWebsiteStore'

export default function LandingPage() {
  const bgImgs: string[] = [
    '/landing-page-hero-section-bg.webp',
    '/landing-page-hero-section-bg-2.webp',
    '/landing-page-hero-section-bg-3.webp',
    '/landing-page-hero-section-bg-4.webp',
  ]
  const HistoryPath = useAppSelector((state) => state.userPathStore.path)
  const location = useLocation()
  const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap
  const game = phaserGame.scene.keys.game as Game

  if (
    HistoryPath === 'gamePlay' &&
    (location.pathname === '/play' ||
      location.pathname === '/' ||
      location.pathname === '/mint' ||
      // location.pathname === "/leaderboard" ||
      location.pathname === '/presale')
  ) {
    if (window.confirm('Are you sure?') == true) {
      if (bootstrap) {
        bootstrap.pauseGame()
        if (game.lobbySocketConnection) game.closeLobbySocketConnection()
        bootstrap.launchBackGroundNight()
        // if (location.pathname === '/play' || location.pathname === '/') {
        //   View = <Home />
        // } else if (location.pathname === '/mint') {
        //   console.log(location.pathname)
        //   View = <MintPage />
        // }
      }
      store.dispatch(ChangePath(location.pathname))
      store.dispatch(SetGameStarted(false))
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
  }
  return (
    <div className='revamped-wrapper'>
      <ScrollToTop />

      <NavigationBar />

      <main className='revamped-landing-page'>
        <HeroSection bgImgs={bgImgs} />
        <AboutSection />
        {/* <MineSection /> */}
        <VideoSection />
        <InfoSection />
        <FeaturesSection />
        <FAQsSection />
        {/* <BottomTextView /> */}
      </main>

      <Footer />
    </div>
  )
}
