// @ts-nocheck
/* eslint @typescript-eslint/no-unused-vars: off */
/* eslint @typescript-eslint/no-explicit-any: off */

import { SYSTEM_WALLETS_MAPPING } from './../../../utils/interface';
import phaserGame from "../../../PhaserGame";
import store from "../../../stores";
import { BrewMachinePunched, GameTurnMouseClickOff, OpenAtmView, OpenServiceView, SelectFightInFightMachineMenu, SetMouseClickControlATM, SetMouseClickControlFightMachine, ShowBrewEjectAnimation, ShowBrewEjectAnimationFromServer, ShowMagnetMoveBrew, TurnMouseClickOff } from "../../../stores/UserActions";
import { HitFightMachine } from "../../../stores/UserActions";
import { ChangeShowMenuBox, ChangeShowQueueBox } from "../../../stores/UserWebsiteStore";
import Boundary, { Rect, calculateRect, calculateRectReverse } from "../../Components/Boundary";
import { IKeysInfo } from "../../characters/IPlayer";
import Bootstrap from "../Bootstrap";
import Game from "../Game";
import { DEFAULT_SPRITE_DISPLAY_HEIGHT } from "../../configs";
import { roundToNDecimalPlaces } from '../../../utils/web3_utils';
import { checkIpad, getSystemInfo } from '../../../utils/systemInfo';
import { BrewComponent } from '../components/BrewComponent';
import { FightMachineComponent } from '../components/FightMachineComponent';
export class BitBuddyHQ {
  game: Game;
  scene: Phaser.Scene;
  atmBase!: Phaser.Tilemaps.TilemapLayer;
  atmArea!: Phaser.Tilemaps.TilemapLayer;
  atmExt!: Phaser.Tilemaps.TilemapLayer;
  atmRect!: Rect;

  serviceArea!: Phaser.Tilemaps.TilemapLayer;
  serviceMachine1Rect!: Rect;

  brewBase!: Phaser.Tilemaps.TilemapLayer;
  brewArea!: Phaser.Tilemaps.TilemapLayer;
  brewExt!: Phaser.Tilemaps.TilemapLayer;
  brewRect!: Rect;
  bootstrap: Bootstrap;
  brewCanSprite!: Phaser.GameObjects.Image;

  fightMachineBase!: Phaser.Tilemaps.TilemapLayer;
  fightMachineExt!: Phaser.Tilemaps.TilemapLayer;
  fightMachineArea!: Phaser.Tilemaps.TilemapLayer;
  FightMachineRect!: Rect;
  // treasury_balance_text!: Phaser.GameObjects.Text;
  jackpot_balance_text!: Phaser.GameObjects.Text;
  pp5_balance_text!: Phaser.GameObjects.Text;
  pp_pool_text!: Phaser.GameObjects.Text;
  wheelChance!: Phaser.GameObjects.Text;
  prizeDropRate!: Phaser.GameObjects.Text;


  goLeftSprite!: Phaser.GameObjects.Image;
  hitFightMachineArrow!: Phaser.GameObjects.Image;

  brewsItems: Array<BrewComponent> = []
  fightMachineItems: Array<FightMachineComponent> = []

  fightMachineItem: FightMachineComponent;

  constructor(scene: Phaser.Scene) {
    this.game = phaserGame.scene.keys.game as Game;
    this.scene = scene;
    this.bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap;
  }

  init() {
    this.game.map = this.scene.make.tilemap({
      key: "hq_bitbuddy.json",
      tileHeight: 16,
      tileWidth: 16
    })
    const isMobile = getSystemInfo();

    const margin = 1;
    const spacing = 2;

    console.log("debnug all assets -- ")

    const hqTilemap = this.game.map.addTilesetImage("HQ_Gym_Sprite_Sheet", "hq_bitbuddy_spritesheet", 16, 16, margin, spacing);

    let layerNum = 0;
    const _1_floor = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const _2_walls = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const _3_wallDecor = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;

    const displayScreen_4 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    displayScreen_4?.setVisible(false)

    const large_arena_5 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    large_arena_5?.setVisible(false)

    const med_arena_6 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    med_arena_6?.setVisible(false)

    const small_arena_7 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    small_arena_7?.setVisible(false)

    // small arena 1 - top left
    const small_arena_9 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const stage_collisions_1 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    stage_collisions_1?.setVisible(false)

    // small arena 2 - top right
    const small_arena_11 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const stage_collisions_2 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    stage_collisions_2?.setVisible(false)

    // small arena 2 - bottom left
    const small_arena_13 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const stage_collisions_3 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    stage_collisions_3?.setVisible(false)

    // small arena 2 - bottom right
    const small_arena_15 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const stage_collisions_4 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    stage_collisions_4?.setVisible(false)

    // pipes
    for (let i = 0; i < 11; i++) {
      this.game.map.createLayer(layerNum, hqTilemap);
      layerNum += 1;
    }

    // brew1
    this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const brewExt1 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const brewArea = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    brewArea.setDepth(-1)
    const brewRect = calculateRectReverse(this.game.map, brewArea);
    this.brewsItems.push(new BrewComponent(brewRect, brewExt1))

    console.log("calculateRectReverse brew1", brewRect)



    this.fightMachineBase = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const fightMachineExt1 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    this.fightMachineArea = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    this.fightMachineArea.setDepth(-1)
    fightMachineExt1.setDepth(1)
    const fightMachineRect1 = calculateRectReverse(this.game.map, this.fightMachineArea);

    // this.fightMachineItems.push(
    //   new FightMachineComponent(
    //     fightMachineRect1,
    //     fightMachineExt1
    //   )
    // )

    this.fightMachineBase = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    this.fightMachineExt = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    this.fightMachineArea = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    this.fightMachineArea.setDepth(-1)
    this.fightMachineExt.setDepth(1)
    this.FightMachineRect = calculateRectReverse(this.game.map, this.fightMachineArea);
    // this.fightMachineItems.push(new FightMachineComponent(this.FightMachineRect, this.fightMachineExt))

    this.fightMachineItem = new FightMachineComponent(
      [fightMachineRect1, this.FightMachineRect],
      [fightMachineExt1, this.fightMachineExt]
    )

    // brew2
    this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const brewExt2 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const brewArea2 = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    brewArea2.setDepth(-1)
    const brewRect2 = calculateRectReverse(this.game.map, brewArea2);
    console.log("calculateRectReverse brew2", brewRect2)
    this.brewsItems.push(new BrewComponent(brewRect2, brewExt2))


    // propslow
    this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    // poster
    this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;

    // benches
    const benches = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    benches?.setVisible(false)

    // frogmachine
    const frogmachine = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    frogmachine?.setVisible(false)

    // budbaker
    this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;


    // service
    this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;

    // atm -- up and down
    const atmUp = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    const atmDown = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    atmDown?.setVisible(false)
    atmUp?.setVisible(false)

    // coin machine
    const coinMachine = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    coinMachine?.setVisible(false)

    // collision
    const wallCollision = this.game.map.createLayer(layerNum, hqTilemap); layerNum += 1;
    wallCollision?.setVisible(false)


    // console.log("layerssss bitbuddy hq ", this.game.map.layers)


  }

  update(keysInfo: IKeysInfo) {
    const tempMyPlayer = this.game.otherPlayers.get(store.getState().web3store.player_id)
    if (tempMyPlayer?.gameObject) {
      for (let i = 0; i < this.brewsItems.length; i++) {
        const item = this.brewsItems[i]
        item.update(tempMyPlayer, keysInfo)
      }
      this.fightMachineItem.update(tempMyPlayer, keysInfo)
    }
  }


  updateSystemWalletText() {
    const data = store.getState().websiteStateStore.system_wallets_info
    // let treasuryText = "Treasury: 0"
    let jpText = "Jackpot: 0"
    let wheelChanceText = "Wheel Chance: 0%"
    let ppText = "Prize Pool: 0"
    let ppPoolText = "Today's Rewards: 0"
    //let prizeDropRateText = "Prize Drops per Hour: 0"
    for (let i = 0; i < data.length; i++) {
      if (data[i].index === SYSTEM_WALLETS_MAPPING.JackPot5) {
        jpText = `Jackpot: ${(roundToNDecimalPlaces(data[i].value / 100)).toLocaleString()} Bits`
      }
      if (data[i].index === SYSTEM_WALLETS_MAPPING.PRIZE_DROP_AMOUNT) {
        ppPoolText = `Today's Rewards: ${(roundToNDecimalPlaces(data[i].value / 100)).toLocaleString()} Bits`
      }
      if (data[i].index === SYSTEM_WALLETS_MAPPING.JACKPOT_PROBABILITY_INFO) {
        let chance = roundToNDecimalPlaces(data[i].value);
        if (chance > 100) chance = 100
        wheelChanceText = `Wheel Chance: ${chance} %`
      }
      if (data[i].index === SYSTEM_WALLETS_MAPPING.PrizePool5) {
        ppText = `Prize Pool: ${(roundToNDecimalPlaces(data[i].value / 100)).toLocaleString()} Bits`
      }
      // if (data[i].index === SYSTEM_WALLETS_MAPPING.JACKPOT_PROBABILITY_INFO) {
      // prizeDropRateText = `Prize Drops per Hour: 5`
      // }
    }
    // this.treasury_balance_text.text = treasuryText
    this.pp5_balance_text.text = ppText;
    this.jackpot_balance_text.text = jpText;
    this.wheelChance.text = wheelChanceText;
    this.pp_pool_text.text = ppPoolText;
    // this.prizeDropRate.text = prizeDropRateText;
  }
}