import { useEffect, useState } from "react";
import { isNullOrUndefined } from "util";
import { randomGenarateBitfightersV2 } from "../../../hooks/ApiCaller";
import { LogOut, SetConnectionMode, SetConnectKitProcessed, Web2LoginV2 } from "../../../stores/Web3Store";
import AnimatedLoader from "../../FullScreenLoader/AnimatedLoader";
import { ethers } from 'ethers'
import { useAppDispatch } from "../../../hooks";
import phaserGame from "../../../PhaserGame";
import Bootstrap from "../../../game/scenes/Bootstrap";
import store from "../../../stores";
import { setNFTLoadedBool } from "../../../stores/BitFighters";
import { FetchGameServerConnection, fetchSystemWalletsInfo } from "../../../utils/game_server_utils";
import { SetGameLoadingState, SetSelectedRoomId, SetShowGameServersList } from "../../../stores/WebsiteStateStore";
import { SetGameStarted } from "../../../stores/PlayerData";
import { Web2LoginNew } from "../../../landing-page/Web2Login";
import { Web3LoginV2 } from "../../../landing-page/Web3Login";


function WalletInfo() {
  const dispatch = useAppDispatch();

  const bootstrap = phaserGame.scene.keys.bootstrap as Bootstrap

  // const [connectedAddresses, setConnectedAddresses] = useState<string[]>([])

  const [web2Loading, setWeb2Loading] = useState(false)
  const [apiLoading, setApiLoading] = useState(false)


  const startGame = async () => {
    store.dispatch(SetGameStarted(true))
    localStorage.setItem('game_state', 'start')
    store.dispatch(SetGameLoadingState(true))
    bootstrap.launchGame(store.getState().playerDataStore.current_game_player_info)
    store.dispatch(SetShowGameServersList(false))
  }


  async function syncUser() {
    if (web2Loading) {
      return
    }
    setWeb2Loading(true)
    dispatch(SetConnectKitProcessed(false))
    try {
      const emailAddress = localStorage.getItem("web2_wallet_address") || ""
      const web3Address = localStorage.getItem("web3_wallet_address") || ""
      console.log("particle debug ---- ", "email--", emailAddress, "web3--", web3Address)

      if (emailAddress && emailAddress !== "") {
        setWeb2Loading(true)
        // assuming unique address ... 
        const bfs = await Web2LoginNew(emailAddress)
        console.log("particle debug ---- bfs ---", bfs)
        localStorage.setItem('web2_wallet_address', emailAddress)
        dispatch(Web2LoginV2(""))


        if (bfs && bfs.length == 0) {
          //
          setWeb2Loading(true)
          setTimeout(async () => {
            setWeb2Loading(true)
            const output = await randomGenarateBitfightersV2(emailAddress, ethers.constants.AddressZero, 1, 'web2')
            console.log("particle debug ---- bfs 2---", output)
            if (!isNullOrUndefined(output)) {
              dispatch(Web2LoginV2(emailAddress))
              dispatch(SetConnectionMode("email"))
              setTimeout(() => {
                window.location.reload()
              }, 500)
            }
            setWeb2Loading(false)
          }, 1000)
        } else if (bfs && bfs.length > 0) {
          dispatch(Web2LoginV2(emailAddress))
          dispatch(SetConnectionMode("email"))
        }

        setWeb2Loading(false)
        dispatch(SetConnectKitProcessed(true))
      } else if (web3Address && web3Address !== "") {
        await Web3LoginV2(web3Address)
      }


      setWeb2Loading(false)
      dispatch(SetConnectKitProcessed(true))
    } catch (err) {
      console.error("error syncuser", err)
      setWeb2Loading(false)
      dispatch(SetConnectKitProcessed(false))
    }
  }

  useEffect(() => {
    syncUser()
  }, [])



  console.log("useConnect --- debug -- ",)
  return (
    <div>
      {(web2Loading || apiLoading) && <AnimatedLoader />}
    </div>
  )
}

// Wrap your application with this component.
export const ConectionWrapper = ({ children }: React.PropsWithChildren) => {
  return (
    <>
      <WalletInfo />
      {children}
    </>
  )
}